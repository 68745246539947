import React, { FC, useMemo, useState } from 'react';
import styles from './kycForm.module.css';
import CustomButton from '../../CustomButton';
import buttonStyles from '../../../assets/styles/customButton.module.css';
import { FileObject } from '../../../types';
import FileUpload from '../../FileUpload';
import { useDispatch } from 'react-redux';
import { showErrorAlert, showSuccessAlert } from '../../../store/actions/alerts';
import countryList from 'react-select-country-list';
import { ApiClient } from '../../../services/apiClient';
import { SubmitHandler, useForm } from 'react-hook-form';

type IFormInput = {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  email?: string;
  documentType: string;
  documentCountry: string;
  frontDocumentImage: string;
  faceImage: string;
  backDocumentImage: string;
};

const intialObject = {
  firstName: '',
  middleName: '',
  lastName: '',
  email: '',
  documentType: '',
  documentCountry: '',
  frontDocumentImage: '',
  faceImage: '',
  backDocumentImage: '',
};

const UserManualKycForm: FC = () => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({ defaultValues: intialObject });
  const options = useMemo(() => countryList().getData(), []);
  const [loading, setLoading] = useState<boolean>(false);
  const [kycId, setKycId] = useState<string>('');
  const [kycStatus, setKycStatus] = useState<string>('');
  const [frontDocumentImage, setFrontDocumentImage] = useState<FileObject>({
    filename: '',
    format: '',
    file: '',
  });
  const [faceImage, setFaceImage] = useState<FileObject>({
    filename: '',
    file: '',
    format: '',
  });
  const [backDocumentImage, setBackDocumentImage] = useState<FileObject>({
    filename: '',
    file: '',
    format: '',
  });

  // On front image success
  const onFrontImageSuccess = (data: FileObject) => {
    setFrontDocumentImage(data);
  };
  // On face image success
  const onFaceImageSuccess = (data: FileObject) => {
    setFaceImage(data);
  };
  // On back image success
  const onBackImageSuccess = (data: FileObject) => {
    setBackDocumentImage(data);
  };

  // On Error
  const onError = (msg: string) => {
    dispatch(showErrorAlert(msg));
  };

  // Handle Submit
  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    if (!faceImage.file || !frontDocumentImage.file || !backDocumentImage.file)
      return dispatch(showErrorAlert('Please upload kyc documents'));
    setLoading(true);
    ApiClient.registerUserDocumentVerification({
      ...data,
      frontDocumentImage: frontDocumentImage.file,
      faceImage: faceImage.file,
      backDocumentImage: backDocumentImage.file,
    })
      .then((response) => {
        setKycId(response.kycId);
        setKycStatus(response.status);
        dispatch(showSuccessAlert(`KYC has been submitted`));
      })
      .catch((err) => dispatch(showErrorAlert(err.message)))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="p-6">
      <div className={`${styles.formWrapper}`}>
        <div>
          <div className={`${styles.inputWrapper}`}>
            <input
              type="text"
              {...register('firstName', { required: false })}
              className={`${styles.input}`}
              placeholder="First Name *"
            />
          </div>
          <p className={styles.errorMessage}>{errors.firstName?.message}</p>
        </div>
        <div className={`${styles.inputWrapper}`}>
          <input type="text" {...register('middleName')} className={`${styles.input}`} placeholder="Middle Name" />
        </div>
        <div>
          <div className={`${styles.inputWrapper}`}>
            <input
              type="text"
              {...register('lastName', { required: false })}
              className={`${styles.input}`}
              placeholder="Last Name *"
            />
          </div>
          <p className={styles.errorMessage}>{errors.lastName?.message}</p>
        </div>
        <div>
          <div className={`${styles.inputWrapper}`}>
            <input
              type="email"
              {...register('email', { required: false })}
              className={`${styles.input}`}
              placeholder="Email *"
            />
          </div>
          <p className={styles.errorMessage}>{errors.email?.message}</p>
        </div>
        <div>
          <div className={`${styles.inputWrapper}`}>
            <select
              {...register('documentType', { required: 'Please select document type' })}
              className={`${styles.input}`}
            >
              <option value="" disabled>
                Select Document Type *
              </option>
              <option value="PP">Passport</option>
              <option value="ID">National ID</option>
              <option value="DL">Driver&lsquo;s License</option>
            </select>
          </div>
          <p className={styles.errorMessage}>{errors.documentType?.message}</p>
        </div>
        <div className="grid grid-cols-1 gap-4">
          <div>
            <div className={`${styles.inputWrapper}`}>
              <select
                {...register('documentCountry', { required: 'Document country is required' })}
                className={`${styles.input}`}
                placeholder="Document Country *"
              >
                <option value="" disabled>
                  Select Document Country *
                </option>
                {options.map((x: { value: string; label: string }) => (
                  <option key={x.value} value={x.value}>
                    {x.label}
                  </option>
                ))}
              </select>
            </div>
            <p className={styles.errorMessage}>{errors.documentCountry?.message}</p>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4 my-6">
        <FileUpload
          value={faceImage}
          label="Add Face Image"
          mediaType="documentImage"
          tooltip="Only Image files (JPG, JPEG, PNG) are allowed and Please provide an image of following dimensions, 1200px X 675px or aspect ratio of 16:9"
          onFileSuccess={onFaceImageSuccess}
          onFileError={onError}
        />
        <FileUpload
          value={frontDocumentImage}
          label="Add Front Document Image"
          mediaType="documentImage"
          tooltip="Only Image files (JPG, JPEG, PNG) are allowed and Please provide an image of following dimensions, 1200px X 675px or aspect ratio of 16:9"
          onFileSuccess={onFrontImageSuccess}
          onFileError={onError}
        />
        <FileUpload
          value={backDocumentImage}
          label="Add Back Document Image"
          mediaType="documentImage"
          tooltip="Only Image files (JPG, JPEG, PNG) are allowed and Please provide an image of following dimensions, 1200px X 675px or aspect ratio of 16:9"
          onFileSuccess={onBackImageSuccess}
          onFileError={onError}
        />
      </div>
      <div className={buttonStyles.buttonWrapper}>
        <CustomButton className={buttonStyles.buttonPrimary} loading={loading} onClick={handleSubmit(onSubmit)}>
          Submit
        </CustomButton>
      </div>

      <div className={styles.kycSubmisstionDetails}>
        <h3>
          KYC Application ID: <span>{kycId}</span>
        </h3>
        <h3>
          KYC status: <span>{kycStatus}</span>
        </h3>
      </div>
    </div>
  );
};

export default UserManualKycForm;
