import React from 'react';
import './styles.scss';
import UserManualKycForm from '../Forms/UserManualKycForm';
import headingStyles from '../../assets/styles/heading.module.css';

export const ManualUserKyc: React.FC = () => {
  return (
    <div className="manualUserKycContainer">
      <h2 className={headingStyles.headingSm}>Perform User Document Verification</h2>
      <UserManualKycForm />
    </div>
  );
};
